<template>
    <div class="activity">
        <div class="activity-list">
            <div
                v-for="(list, index) in activityList"
                :key="list.activity_id"
                @click="goActivityDetail(list.activity_id, list.extra_id)"
            >
                <div class="list-item" v-if="judgeShowCoupon(list.activity_state, list.is_visible)">
                    <div class="list-header">
                        <p class="van-ellipsis">{{ list.activity_title }}</p>
                        <span
                            v-if="!list.state && list.is_distribute"
                            :class="{ bgColor: list.is_distribute, bgColor1: list.state }"
                        >
                            已参与
                        </span>
                        <img
                            class="activity-invalid"
                            v-else-if="list.state && list.is_distribute"
                            src="@/assets/images/staff_promotion_invalid.png"
                            alt=""
                        />
                    </div>
                    <div class="activity-label">
                        <span class="labelType">{{ list.activity_resource_type }}</span>
                        <span v-for="(label, idx) in list.keywords" :key="idx">
                            <span>{{ label }}</span>
                        </span>
                    </div>
                    <div class="list-item-body">
                        <div class="body-left">
                            已有
                            <span class="num">{{ list.total_distribute_num }}</span>
                            人参与推广
                            <span v-if="list.self_invite_num">
                                ，已锁客
                                <span class="num">{{ list.self_invite_num }}</span>
                                人
                            </span>
                        </div>
                        <div class="body-right">
                            <button
                                class="body-action"
                                v-if="list.activity_state == 4 || list.activity_state == 3"
                                :class="[list.activity_state == 4 || list.activity_state == 3 ? 'grey-button' : ' ']"
                            >
                                已失效
                            </button>
                            <!--  <button class="body-action" @click.stop="goActivityDetail(list.activity_id)" v-if="!list.state">活动详情</button>-->
                            <button
                                class="body-action"
                                @click.stop="
                                    immedPromote(list.activity_id, list.is_distribute, index, list.relation_type)
                                "
                                v-if="!list.state && list.activity_state !== 4 && list.activity_state !== 3"
                            >
                                立即推广
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <invitate-dialog
            ref="invitateDialog"
            v-model="isShowDialog"
            :isInList="isInList"
            :activityType="isCurrentCoupon ? 'coupon' : 'activity'"
            :distributeUrl="distributeUrl"
            :posterData="posterData"
            :share_content="share_content"
        />
    </div>
</template>
<script>
import invitateDialog from './invitateDialog';
import { fetchActivityDetail, fetchDistributeUrl, fetchDistributeAvatar } from '../api/index';
import { getCookie } from '@/utils/cookies.js';

const RELATION_TYPE_MAP = {
    COUPON: 3 // 优惠券
};

export default {
    components: { invitateDialog },
    props: {
        activityList: {
            type: Array,
            default: () => []
        },
        lookMore: {
            type: Boolean,
            default: false
        },
        isJoinedActivity: {
            type: Number,
            default: 2
        }
    },
    computed: {},
    data() {
        return {
            isInList: false, // 用户是否在灰度名单
            isShowDialog: false,
            distributeUrl: '', // 推广链接
            posterData: {}, // 海报信息
            immedLoading: false,
            isCurrentCoupon: false,
            share_content: ''
        };
    },
    created() {
        this.getDistributeAvatar();
    },
    methods: {
        // 请求 后端转过的 企微头像地址
        getDistributeAvatar() {
            let app_id = getCookie('with_app_id');
            let user_id = localStorage.getItem('userId');
            fetchDistributeAvatar({
                app_id,
                user_id
            })
                .then((res) => {
                    if (res.code === 0) {
                        this.avatarSrc = res?.data.staff_avatar ?? '';
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        judgeShowCoupon(coupon_state, is_visible) {
            if (!is_visible) return false;
            //不可见数据前端过滤 无奈之举........
            if (coupon_state == 4 && this.isJoinedActivity == 1) return false;
            //失效活动 进行中的活动不展示 //失效活动 已参与的活动要展示
            if (coupon_state == 4 && !this.lookMore) return true;
            //活动中心前二十条得展示已失效 不然 全都是不可见 那就空了 没法就这么返回的
            if (coupon_state == 3 && this.isJoinedActivity == 1) return false;
            //如果是进行中不展示已经结束的
            return true;
        },
        goActivityDetail(id, coupon_id) {
            let path = `/staff_promotion/activity_detail/${id}`;
            if (coupon_id) path += `/${coupon_id}`;
            this.$router.push({
                path: path
            });
        },
        // 点击立即推广按钮
        immedPromote(activity_id, isJoin, index, relation_type) {
            if (this.immedLoading) return;

            this.isCurrentCoupon = relation_type === RELATION_TYPE_MAP.COUPON;

            this.immedLoading = true;
            this.$toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });
            const requestArr = [fetchActivityDetail({ activity_id }), this.$refs.invitateDialog.getDistributeSeting()];
            // 未参与推广时，需要发请求获取推广链接
            if (!isJoin) requestArr.push(fetchDistributeUrl({ activity_id }));

            Promise.all(requestArr)
                .then((res) => {
                    if (res[0].code === 0 && res[1].code === 0) {
                        const data = res[0].data;
                        this.isInList = data.open_wework_distribute;
                        this.distributeUrl = data.distribute_url;
                        this.share_content = data.share_content
                        // 海报信息, 优惠券的 poster_conf为 null
                        this.posterData = data.poster_conf || {}; // 基本坐标信息
                        this.posterData.nickname = data.staff_name; // 推广员名字
                        this.posterData.avatarSrc = this.avatarSrc; // 推广员头像
                        this.posterData.qrcodeSrc = data.distribute_url; // 二维码链接
                        if (!isJoin && res[2].code === 0) {
                            this.distributeUrl = res[2].data.distribute_url;
                            this.posterData.qrcodeSrc = res[2].data.distribute_url;
                            // 调了 fetchDistributeUrl 接口就代表已参与，需要前端更新状态为 1，防止重复请求该接口导致重复参与
                            this.activityList[index].is_distribute = 1;
                        }
                        this.isShowDialog = true;
                    }
                    this.$toast.clear();
                    this.immedLoading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.immedLoading = false;
                    this.$toast.clear();
                });
        },
        activityState(isParticipate, state) {
            if (state && isParticipate) return '已失效';
            if (!state && isParticipate) return '已参与';
        }
    }
};
</script>
<style lang="scss" scoped>
.activity {
    margin: 0 0.32rem;
    .activity-list {
        .list-item {
            overflow: hidden;
            padding: 0.24rem;
            background: #fff;
            border-radius: 0.16rem;
            margin-bottom: 0.2rem;
            display: flex;
            flex-direction: column;
            position: relative;
            & > .list-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                & > p {
                    font-size: 0.32rem;
                    color: #333333;
                    font-weight: bold;
                    display: inline-block;
                    width: 85%;
                }
                .bgColor {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: inline-block;
                    font-size: 0.2rem;
                    width: 46px;
                    height: 22px;
                    border-radius: 0;
                    color: #f68d1c;
                    background: rgba($color: #f68d1c, $alpha: 0.1);
                    border-bottom-left-radius: 0.2rem;
                    border-top-right-radius: 0.16rem;
                    text-align: center;
                    line-height: 0.44rem;
                    font-weight: bold;
                }
                .bgColor1 {
                    position: absolute;
                    right: 0;
                    top: 0;
                    display: inline-block;
                    font-size: 0.2rem;
                    width: 46px;
                    height: 22px;
                    border-radius: 0;
                    color: #999999;
                    background: #eeeeee;
                    border-bottom-left-radius: 0.2rem;
                    border-top-right-radius: 0.16rem;
                    text-align: center;
                    line-height: 0.44rem;
                    font-weight: bold;
                }
            }
            & > .list-item-body {
                display: flex;
                justify-content: space-between;
                margin-top: 0.2rem;
                color: #666666;
                .body-left {
                    width: 0;
                    flex: 1;
                    font-size: 0.2rem;
                    line-height: 0.52rem;
                    margin-right: 0.16rem;
                    .num {
                        font-size: 0.36rem;
                        font-family: 'D-DIN-Bold';
                        color: #f68d1c;
                    }
                }
                .body-action {
                    background-image: linear-gradient(-58deg, #ff641a 0%, #ff3f0b 100%);
                    color: #fff;
                    border-radius: 0.6rem;
                    width: 1.6rem;
                    height: 0.52rem;
                    border: none;
                    font-size: 0.24rem;
                    font-weight: bold;
                    &:first-child {
                        margin-right: 0.16rem;
                    }
                }
                .grey-button {
                    background-image: linear-gradient(-58deg, #808080 0%, #808080 100%);
                }
            }
            & > .activity-label {
                display: inline-block;
                margin-top: 0.2rem;
                .labelType {
                    color: #1472ff;
                    background: rgba($color: #1472ff, $alpha: 0.1);
                }
                & > span {
                    font-size: 0.2rem;
                    display: inline-block;
                    background: rgba($color: #f68d1c, $alpha: 0.1);
                    color: #f68d1c;
                    margin-right: 0.08rem;
                    padding: 0.04rem 0.16rem;
                    border-radius: 0.04rem;
                    margin-bottom: 0.08rem;
                }
            }
        }
        .activity-invalid {
            width: 1.2rem;
            position: absolute;
            bottom: -0.2rem;
            right: -0.2rem;
        }
    }
}
</style>
