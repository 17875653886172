<template>
    <div class="fixed promotion_activity" v-loading="isLoading">
        <!-- <div class="activity_nav" :class="{ topBgc: isTop }">
            <div>
                全部活动
                <span>({{ allActivityNum }})</span>
            </div>

            <div class="my_participated">
                <van-checkbox v-model="lookMyParticipated" icon-size=".28rem">
                    只看我参与的 ({{ myParticipated }})
                </van-checkbox>
            </div>
        </div> -->
        <van-tabs class="activity-tab" v-model="active" @change="switchTab" line-width=".32rem" line-height=".06rem">
            <van-tab v-for="item in activityTabList" :title="item.label" :key="item.name" :name="item.name"></van-tab>
        </van-tabs>
        <div class="main-wrap" ref="mainWrap">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh" :class="{ empty: showEmpty }">
                <van-list v-model="loading" :finished="finished" @load="onLoad" :immediate-check="false">
                    <activityList :activityList="activityList" :lookMore="true" :isJoinedActivity="filterType" />
                </van-list>
                <div class="invalidPromotion" v-if="showEmpty">
                    <img src="@/assets/images/promotion_invalid.png" alt="" />
                    <p>没有更多了</p>
                </div>
            </van-pull-refresh>
            <xiaoEFooter :class="{ 'xiaoE-footer': showEmpty || activityList.length < 3 }"></xiaoEFooter>
        </div>
    </div>
</template>
<script>
import activityList from '@/views/staffPromotion/components/activityList';
import xiaoEFooter from '@/views/staffPromotion/components/footer';
import { fetchMoreActivityList } from '@/views/staffPromotion/api';
const INPROGRESS = 1;
const PARTICIPATE = 2;
export default {
    components: {
        activityList,
        xiaoEFooter
    },
    data() {
        return {
            allActivityNum: 0,
            myParticipated: 0,
            lookMyParticipated: false,
            activityList: [],
            filterType: 1, //1-全部活动 2- 我参与的
            page: 1,
            pageSize: 10,
            loading: false,
            finished: false,
            refreshing: false, //下拉刷新
            total: 0,
            isLoading: true,
            isTop: false,
            showEmpty: false,
            active: 1,
            INPROGRESS,
            PARTICIPATE,
            activityTabList: Object.freeze([
                { label: '进行中', name: INPROGRESS },
                { label: '已参与', name: PARTICIPATE }
            ])
        };
    },
    watch: {
        lookMyParticipated(val) {
            this.page = 1;
            val ? (this.filterType = 2) : (this.filterType = 1);
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: '加载中...'
            });
            this.initActivityList();
        }
    },
    mounted() {
        this.initActivityList();
        this.watchScroll();
    },
    methods: {
        initActivityList() {
            this.loading = true;
            let params = {
                type: this.filterType,
                page: this.page,
                page_size: this.pageSize
            };
            fetchMoreActivityList(params)
                .then((res) => {
                    const { list, total } = res?.data;
                    this.isLoading = false;
                    this.$toast.clear();
                    if (params.page === 1) {
                        this.handleActivityListData(res.data);
                        this.finished = false;
                        this.loading = false;
                    } else {
                        this.pushActivityListData(list);
                        this.loading = false;
                    }
                    if (this.activityList.length >= total) {
                        this.finished = true;
                        this.loading = true;
                    }
                })
                .catch((err) => {
                    console.log('err: ', err);
                    this.$toast.clear();
                    this.$toast.fail('获取列表失败', err);
                });
        },
        onLoad() {
            this.page++;
            this.initActivityList();
        },
        // 下拉刷新
        onRefresh() {
            this.page = 1;
            this.initActivityList();
            this.refreshing = false;
        },
        watchScroll() {
            this.$refs.mainWrap.addEventListener('scroll', () => {
                this.$refs.mainWrap.scrollTop > 0 ? (this.isTop = true) : (this.isTop = false);
            });
        },
        handleActivityListData(data) {
            const { list, self_count, total_count } = data;
            this.activityList = list;
            list.length ? (this.showEmpty = false) : (this.showEmpty = true);
            this.myParticipated = self_count;
            this.allActivityNum = total_count;
        },
        pushActivityListData(list) {
            this.activityList = [...this.activityList, ...list];
        },
        switchTab(e) {
            this.filterType = e;
            this.page = 1;
            this.initActivityList();
        }
    }
};
</script>
<style lang="scss" scoped>
.promotion_activity {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    height: 100%;
    position: fixed;
    .activity_nav {
        height: 1.12rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-shrink: 0;
        padding: 0 0.32rem;
        & > div:nth-child(1) {
            font-size: 0.32rem;
            color: #333;
            line-height: 0.48rem;
            font-weight: bold;
        }
        ::v-deep .my_participated {
            div {
                span {
                    color: #999999;
                    font-size: 0.24rem;
                }
            }
        }
    }
    .activity-tab {
        padding: 0 16px;
        margin-bottom: 16px;
        ::v-deep.van-tabs__nav {
            background: unset !important;
        }
    }
    .main-wrap {
        overflow-y: scroll;
        height: 100%;
        width: 100%;
    }
    .empty {
        height: 100%;
    }
    .emptyMain {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }
    .topBgc {
        background: #fff;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
        position: relative;
        z-index: 1;
    }
    .invalidPromotion {
        text-align: center;
        width: 2.88rem;
        height: 2.88rem;
        position: absolute;
        top: 43%;
        left: 50%;
        transform: translate(-50%, -50%);
        img {
            width: 2rem;
            height: 2rem;
        }
        p {
            text-align: center;
            color: #999999;
            font-size: 0.28rem;
        }
    }
    .xiaoE-footer {
        position: absolute;
        bottom: 0.5rem;
        left: 0;
        right: 0;
        margin: 0 auto;
    }
}
</style>
